import {
  QUATRO_ABOUT,
  QUATRO_CLINICS,
  QUATRO_COACHES,
  QUATRO_CONTACT,
  QUATRO_GETAWAYS,
  QUATRO_HOME,
} from 'constants/pages';

export const quatroLinks = [
  { title: 'Home', href: QUATRO_HOME },
  { title: 'About', href: QUATRO_ABOUT },
  { title: 'Clinics', href: QUATRO_CLINICS },
  { title: 'Getaways', href: QUATRO_GETAWAYS },
  { title: 'Coaches', href: QUATRO_COACHES },
];

export const socialLinks = {
  facebook: 'https://www.facebook.com/profile.php?id=61556740563845&_rdr',
  instagram: 'https://www.instagram.com/quatropickleball/',
};
