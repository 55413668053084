import React from 'react';
import Link from 'next/link';
import { ButtonLinkProps } from './types';
import { sizeStyles, variantStyles } from './utils';

const ButtonLink: React.FC<ButtonLinkProps> = ({
  children,
  size = 'md',
  variant = 'primary',
  block = false,
  className = '',
  href,
  ...props
}) => {
  const baseStyles =
    'text-center outline-0 rounded-md focus:outline-none transition duration-150 ease-in-out inline-block';

  const blockStyles = block ? 'w-full' : 'w-fit';

  const combinedStyles = `${baseStyles} ${sizeStyles[size]} ${variantStyles[variant]} ${blockStyles} ${className}`;

  return (
    <Link href={href} {...props} className={combinedStyles}>
      {children}
    </Link>
  );
};

export default ButtonLink;
