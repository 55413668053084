import React from 'react';
import { ReactNode } from 'react';
import NextHead from 'next/head';
import Head from 'components/utilities/Head';
import Footer from './Footer';
import Navbar from './Navbar';

export const OG_IMAGE = `${process.env.APP_URL}/icons/quatro-apple-icon.png`;

interface LayoutProps {
  children: ReactNode;
}

const QuatroLayout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="flex min-h-screen flex-col">
      <Head ogImage={OG_IMAGE} />
      <NextHead>
        <link rel="shortcut icon" href={`${process.env.APP_URL}/icons/quatro-favicon.png`} />
        <link rel="apple-touch-icon" href={`${process.env.APP_URL}/icons/quatro-apple-icon.png`} />
      </NextHead>
      <Navbar />
      <main className="flex-1">{children}</main>
      <Footer />
    </div>
  );
};

export default QuatroLayout;
