import { useState } from 'react';
import { usePathname } from 'next/navigation';
import { QUATRO_CONTACT } from 'constants/pages';
import Menu from 'svg/Menu';
import { ButtonText } from 'components/Button';
import Link from 'components/Link';
import { ButtonLink } from 'components/Quatro/Button';
import classNames from 'styles/utils/classNames';
import { quatroLinks } from './data';

const Navbar = () => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const pathname = usePathname();

  return (
    <nav className="sticky top-0 z-[100] h-[theme(height.quatro-nav)] w-full bg-color-bg-lightmode-primary shadow-sm dark:bg-color-bg-darkmode-primary">
      <div className="container mx-auto flex h-full items-center justify-between px-4 py-2 sm:px-0">
        <img
          src="/images/quatro/logo.png"
          loading="lazy"
          alt="quatro-logo"
          className="h-full w-auto scale-[1.25]"
        />
        <div className="flex items-center gap-8 max-md:hidden">
          {quatroLinks.map((link) => (
            <Link
              key={link.title}
              href={link.href}
              className={classNames(
                'typography-product-button-label-large text-color-text-lightmode-secondary  dark:text-color-text-darkmode-secondary',
                pathname === link.href && '!text-brand-fire-450',
              )}
            >
              {link.title}
            </Link>
          ))}
        </div>
        <ButtonLink href={QUATRO_CONTACT} variant="primary" className="max-md:hidden">
          Contact
        </ButtonLink>
        <ButtonText className="md:hidden" onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}>
          <Menu className="text-brand-gray-1000" />
        </ButtonText>
      </div>
      {/* mobile nav */}
      <div
        className={classNames(
          'fixed top-[theme(height.quatro-nav)] z-[100] h-0  w-full overflow-hidden bg-color-bg-lightmode-primary transition-all  duration-300 ease-in-out dark:bg-color-bg-darkmode-primary md:hidden',
          isMobileNavOpen && 'h-[calc(100dvh_-_theme(height.quatro-nav))]',
        )}
      >
        <div className="flex h-full w-full flex-col gap-10 p-8 text-center">
          {quatroLinks.map((link) => (
            <Link
              key={link.title}
              href={link.href}
              className={classNames(
                'typography-informative-subheading-compact text-color-text-lightmode-secondary  dark:text-color-text-darkmode-secondary',
                pathname === link.href && '!text-brand-fire-450',
              )}
            >
              {link.title}
            </Link>
          ))}
          <ButtonLink block variant="primary" href={QUATRO_CONTACT} className="mt-auto" size="lg">
            Contact
          </ButtonLink>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
