const YtOutlined: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill="currentColor"
      d="M12 4c.855 0 1.732.022 2.582.058l1.004.048.961.057.9.061.822.064a3.8 3.8 0 0 1 3.494 3.423l.04.425.075.91c.07.943.122 1.971.122 2.954 0 .983-.052 2.011-.122 2.954l-.075.91-.04.425a3.8 3.8 0 0 1-3.495 3.423l-.82.063-.9.062-.962.057-1.004.048c-.86.037-1.721.057-2.582.058a61.923 61.923 0 0 1-2.582-.058l-1.004-.048-.961-.057-.9-.062-.822-.063a3.8 3.8 0 0 1-3.494-3.423l-.04-.425-.075-.91A41 41 0 0 1 2 12c0-.983.052-2.011.122-2.954l.075-.91.04-.425A3.8 3.8 0 0 1 5.73 4.288l.821-.064.9-.061.962-.057 1.004-.048c.86-.037 1.722-.057 2.583-.058Zm0 2c-.825 0-1.674.022-2.5.056l-.978.047-.939.055-.882.06-.808.063a1.8 1.8 0 0 0-1.666 1.623C4.11 9.113 4 10.618 4 12c0 1.382.11 2.887.227 4.096.085.872.777 1.55 1.666 1.623l.808.062.882.06.939.056.978.047c.826.034 1.675.056 2.5.056s1.674-.022 2.5-.056l.978-.047.939-.055.882-.06.808-.063a1.8 1.8 0 0 0 1.666-1.623C19.89 14.887 20 13.382 20 12c0-1.382-.11-2.887-.227-4.096a1.8 1.8 0 0 0-1.666-1.623l-.808-.062-.882-.06-.939-.056-.978-.047A60.994 60.994 0 0 0 12 6Zm-2 3.575a.6.6 0 0 1 .819-.559l.081.04 4.2 2.424a.602.602 0 0 1 .085.98l-.085.06-4.2 2.425a.6.6 0 0 1-.894-.43l-.006-.09v-4.85Z"
    />
  </svg>
);

export default YtOutlined;
