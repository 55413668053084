import { ButtonProps } from './types';
import { sizeStyles, variantStyles } from './utils';

const Button: React.FC<ButtonProps> = ({
  children,
  size = 'md',
  variant = 'primary',
  block = false,
  className = '',
  ...props
}) => {
  const baseStyles = 'outline-0 rounded-md focus:outline-none transition duration-150 ease-in-out';

  const blockStyles = block ? 'w-full' : '';

  return (
    <button
      className={`${baseStyles} ${sizeStyles[size]} ${variantStyles[variant]} ${blockStyles} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
