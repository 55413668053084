export const sizeStyles = {
  sm: 'px-4 py-1 text-xs',
  md: 'px-4 py-2 text-sm',
  lg: 'px-5 py-2 text-lg',
};

export const variantStyles = {
  primary:
    'bg-brand-fire-450 text-color-text-lightmode-invert  border border-transparent hover:bg-brand-fire-550',
  secondary:
    'border-brand-gray-1000 border text-brand-gray-1000 bg-transparent hover:bg-color-bg-lightmode-primary ',
  'secondary-invert':
    'border-white text-color-text-lightmode-invert border bg-transparent hover:bg-[#0F0F1099]',
};
