import ArrowRight from 'svg/ArrowRight';
import FacebookOutlined from 'svg/FacebookOutlined';
import InstaOutlined from 'svg/InstaOutlined';
import LogoNav from 'svg/LogoNav';
import YtOutlined from 'svg/YtOutlined';
import { ButtonLinkText } from 'components/Button';
import Link from 'components/Link';
import { quatroLinks, socialLinks } from './data';

const Footer = () => {
  return (
    <footer className="container mx-auto flex h-full min-h-72 w-full gap-4 px-4 pb-16 pt-16 max-md:flex-wrap max-sm:gap-8 sm:px-0">
      <div className="flex-grow-1 flex flex-col gap-4">
        <img
          src="/images/quatro/logo.png"
          className="max-w-36 scale-[1.25]"
          alt="app-logo"
          loading="lazy"
        />
        <div className="mt-auto flex gap-4">
          <ButtonLinkText href={socialLinks.facebook}>
            <FacebookOutlined className="h-6 w-6 fill-none text-brand-gray-700" />
          </ButtonLinkText>
          <ButtonLinkText href={socialLinks.instagram}>
            <InstaOutlined className="h-6 w-6 text-brand-gray-700" />
          </ButtonLinkText>
          <YtOutlined className="hidden h-6 w-6 text-brand-gray-700" />
        </div>
      </div>
      <div className="flex flex-grow-[2] items-start  justify-center gap-4 max-md:flex-col max-md:items-center">
        {quatroLinks.map((link) => (
          <Link
            key={link.title}
            href={link.href}
            className="typography-product-button-label-medium text-color-text-lightmode-secondary   dark:text-color-text-darkmode-secondary"
          >
            {link.title}
          </Link>
        ))}
      </div>
      <div className="flex-grow-1 flex flex-col">
        <LogoNav className="h-8" />
        <p className="typography-product-body-highlight mt-2 max-w-40 text-color-text-lightmode-tertiary dark:text-color-text-darkmode-tertiary">
          Bounce is the #1 app for pickleball players
        </p>
        <ButtonLinkText href="/" className="mt-auto inline-flex gap-2 text-color-brand-primary">
          Visit Bounce
          <ArrowRight className="h-4 w-4" />
        </ButtonLinkText>
      </div>
    </footer>
  );
};

export default Footer;
